body {
  font-family: "Space Grotesk" !important;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background: #1d252e !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* 
@font-face {
  font-family: "noto_sansbold";
  src: url("assets/fonts/notosans-bold-webfont.woff2") format("woff2"),
    url("assets/fonts/notosans-bold-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
} */

/* @font-face {
  font-family: 'barlowregular';
  src: local("barlowregular"), url('./fonts/barlow-regular-webfont.woff2') format('woff2'),
       local("barlowregular"), url('./fonts/barlow-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'barlowbold';
  src: url('./fonts/barlow-bold-webfont.woff2') format('woff2'),
       url('./fonts/barlow-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'barlowmedium';
  src: url('./fonts/barlow-medium-webfont.woff2') format('woff2'),
       url('./fonts/barlow-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'barlowsemibold';
  src: url('./fonts/barlow-semibold-webfont.woff2') format('woff2'),
       url('./fonts/barlow-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
} */

@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Bold.woff2") format("woff2"),
    url("./fonts/SpaceGrotesk-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Medium.woff2") format("woff2"),
    url("./fonts/SpaceGrotesk-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-Regular.woff2") format("woff2"),
    url("./fonts/SpaceGrotesk-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("./fonts/SpaceGrotesk-SemiBold.woff2") format("woff2"),
    url("./fonts/SpaceGrotesk-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/*Common style*/
.main-heading {
  font-size: 22px;
  color: #ffffff;
  position: relative;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.main-heading strong {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  /* text-transform: capitalize; */
  display: inline-block;
  max-width: 50%;
}

.main-heading span,
.breadcrumb-heading span.breadcrumb {
  font-size: 13px;
  font-weight: 500;
  position: relative;
  padding-left: 20px;
  display: inline-flex;
}
.breadcrumb-heading span.breadcrumb.pointer,
.main-heading span.breadcrumb.pointer {
  cursor: pointer;
}
.breadcrumb-heading span.breadcrumb.pointer:hover,
.main-heading span.breadcrumb.pointer:hover {
  color: #f1b924 !important;
}

.main-heading span::before,
.breadcrumb-heading span.breadcrumb::before {
  content: "";
  position: absolute;
  left: 5px;
  background: url("/src/img/left-arrow.svg");
  width: 10px;
  height: 10px;
  top: 0;
  z-index: 1;
  background-size: 100%;
  bottom: 0px;
  margin: auto;
}

.remember .MuiCheckbox-colorSecondary.Mui-checked {
  color: #0059ff;
}
.main-heading::before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 5px;
  background: #fac200;
}
.chip-wrap {
  display: inline-flex;
}
.full-content {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
}
.breadcrumb-heading {
  font-size: 18px;
  color: #ffffff;
  position: relative;
  display: flex;
  align-items: center;
}
.breadcrumb-heading i {
  cursor: pointer;
  margin-right: 10px;
  display: flex;
}
.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
div.MuiPaper-root {
  background: transparent;
}

.btn-wrapper {
  display: inline-flex;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1d252e inset !important;
  -webkit-text-fill-color: #ffffff !important;
}

.login-form input:-webkit-autofill,
.login-form input:-webkit-autofill:hover,
.login-form input:-webkit-autofill:focus,
.login-form input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  -webkit-text-fill-color: #1d252e !important;
}

.MuiButton-iconSizeMedium > *:first-child {
  font-size: 24px !important;
}

/*Autocomplete dropdown style*/
.MuiAutocomplete-popper {
  background: #ffffff;
  padding: 0;
}
.MuiAutocomplete-popper ul {
  padding: 0;
}
.MuiAutocomplete-popper ul li {
  font-size: 12px;
  border-bottom: 1px solid #676767;
  background: #ffffff;
  padding: 5px 10px;
}
.MuiAutocomplete-popper ul li:hover {
  background: #f1b924;
  font-weight: 700;
}

/*multi-select-dropdown*/
.MuiPaper-root li.MuiMenuItem-root {
  padding: 3px 20px 3px 10px;
  border-bottom: 1px solid #e9edf5;
  background: #ffffff;
  font-size: 12px;
  font-family: "Space Grotesk" !important;
}
.MuiPaper-root li.MuiMenuItem-root:hover {
  background: #f1b924;
}
.MuiPaper-root li.MuiMenuItem-root .MuiCheckbox-colorSecondary {
  padding: 3px 2px;
  position: relative;
}
.MuiPaper-root li.MuiMenuItem-root .MuiCheckbox-colorSecondary.Mui-disabled,
.MuiPaper-root
  li.MuiMenuItem-root
  .MuiCheckbox-colorSecondary.Mui-disabled
  + div {
  pointer-events: none;
}
.MuiPaper-root li.MuiMenuItem-root.Mui-disabled {
  opacity: 1;
  background-color: #e2e5ea !important;
}
.MuiPaper-root li.MuiMenuItem-root .MuiListItemText-root {
  margin-top: 2px;
  margin-bottom: 2px;
}
.MuiPaper-root li.MuiMenuItem-root .MuiListItemText-root .MuiTypography-body1 {
  font-size: 11px;
  color: #363636;
}
.MuiPaper-root li.MuiMenuItem-root .MuiCheckbox-colorSecondary.Mui-checked {
  color: #0059ff;
}
/* .MuiPaper-root li.MuiMenuItem-root:hover {
  background: #ffffff;
} */
.MuiPaper-root li.MuiListItem-root.Mui-selected {
  background-color: #ffffff;
}
.MuiPaper-root li.MuiListItem-root.Mui-selected:hover {
  background-color: #f1b924;
}
.MuiMenu-paper.MuiPopover-paper {
  /* max-height: 300px; */
  /* margin-top: 85px; */
  /* margin-left: -20px; */
}
.MuiMenu-paper.MuiPopover-paper::-webkit-scrollbar {
  width: 5px;
}
.MuiMenu-paper.MuiPopover-paper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.MuiMenu-paper.MuiPopover-paper::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
.MuiMenu-paper.MuiPopover-paper .bottom-btn {
  position: absolute;
  bottom: 0;
  background: #ffffff;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 1;
}
.MuiMenu-paper.MuiPopover-paper .bottom-btn li {
  list-style-type: none;
  display: inline-flex;
}
/*normal dropdown*/
.MuiListItem-gutters {
  padding: 0 10px;
}
.MuiPopover-paper .MuiMenu-list .MuiListItem-gutters .fixed-btn {
  padding: 0;
}
.MuiPopover-paper .MuiMenu-list .MuiListItem-gutters .fixed-btn li {
  list-style-type: none;
  display: inline-block;
}
.MuiMenu-paper.MuiPopover-paper .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}
.MuiAutocomplete-popper {
  padding-top: 0px !important;
  background: transparent !important;
  margin: 0px !important;
}
.MuiAutocomplete-popper ul,
.MuiAutocomplete-popper .MuiAutocomplete-noOptions {
  background: #ffffff !important;
}

.long-menu.MuiPaper-root {
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #1d252e !important;
  box-shadow: 0px 3px 6px #00000066 !important;
  border: 1px solid #77797d;
  padding: 4px 0px;
  border-radius: 5px !important;
}
.long-menu.MuiPaper-root .MuiList-padding {
  padding: 0px !important;
  text-align: -webkit-right;
}
.long-menu.MuiPaper-root li.MuiMenuItem-root {
  display: table;
  color: #fff;
  background-color: transparent;
  font-size: 12px;
  box-sizing: border-box;
  font-family: "Space Grotesk" !important;
  white-space: nowrap;
  border-radius: 16px;
  margin: 8px 5px;
  padding: 2px 10px;
  border: 1px solid #fff;
  vertical-align: middle;
}

.long-menu.MuiPaper-root li.MuiMenuItem-root:hover {
  background: transparent;
  font-weight: 700;
}

.long-menu.MuiPaper-root .MuiTouchRipple span {
  display: inline-block;
  vertical-align: middle;
}

.long-menu.MuiPaper-root .MuiTouchRipple span svg {
  display: inline-block;
  vertical-align: middle;
  color: #fbeabc;
  width: 20px;
  height: 20px;
  font-size: 12px;
  margin-right: 5px;
}

/* .MuiList-padding{
  padding-top: 0px !important;
} */

.MuiButton-root {
  font-family: "Space Grotesk" !important;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/*Modal styles*/
.MuiDialog-paperWidthSm {
  max-width: 450px !important;
}
div.MuiDialog-paper {
  background: #ffffff;
  padding: 20px;
}
div.MuiDialogTitle-root {
  padding: 0px;
}
div.MuiDialogContent-root {
  border: 0px;
}
div.MuiDialogActions-root {
  justify-content: center;
}
div.MuiDialogContent-root p.MuiTypography-body1 {
  font-size: 13px;
  font-family: "Space Grotesk" !important;
  text-align: center;
}
div.MuiDialogActions-root button.secondary-btn {
  background-color: #ffffff !important;
  color: #1d252e !important;
}
div.MuiDialogTitle-root .MuiButtonBase-root {
  top: 5px;
  color: #9e9e9e;
  right: 5px;
  position: absolute;
  padding: 0;
}
/*datepicker styles*/
.MuiPickersDay-daySelected {
  background: #f1b924 !important;
}
.MuiPickersBasePicker-container {
  background: #ffffff;
}

.top-section .last-visited ul.MuiList-root .chip-wrap {
  display: flex;
}
.top-section .last-visited ul.MuiList-root .chip-wrap ul {
  background: #1d252e;
  margin: 0;
  padding: 10px;
  position: absolute;
  right: -20px;
  border: 1px solid #47484b;
  border-radius: 7px;
  top: 5px;
  flex-wrap: wrap;
  text-align: right;
  box-shadow: 0px 3px 25px #00000066;
}
.top-section .last-visited ul.MuiList-root .chip-wrap ul li {
  width: 100%;
  margin-bottom: 10px;
}
.top-section .last-visited ul.MuiList-root .chip-wrap ul li:last-child {
  margin-bottom: 0px;
}
.ps__thumb-y {
  width: 4px !important;
  right: 0px !important;
}
.ps--active-y > .ps__rail-y {
  background: #000000 !important;
}
.ps__rail-y {
  width: 4px !important;
}
.MuiPopover-root.optionMenu .MuiPopover-paper {
  margin-top: 35px !important;
  border-top: 2px solid #fac200;
  border-radius: 0;
}
.MuiPopover-root.optionMenu .MuiPopover-paper ul {
  padding-top: 0px;
  padding-bottom: 0px;
}
.MuiPopover-root.optionMenu .MuiPopover-paper ul li {
  border-bottom: 0px !important;
  text-decoration: underline;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 20px 5px 10px;
}
.MuiPickersCalendar-week .MuiIconButton-root:hover {
  border: 1px solid #f1b924;
}
.MuiAutocomplete-clearIndicator {
  visibility: hidden !important;
}
.MuiPaper-root li.MuiListItem-root.view-last {
  padding: 5px 25px 5px 10px;
  text-align: left;
}
.MuiPaper-root li.MuiListItem-root.Mui-selected.view-last {
  background: #ffffff;
  position: relative;
}
.MuiPaper-root li.MuiListItem-root.Mui-selected.view-last::after {
  content: "";
  position: absolute;
  right: 5px;
  top: 10px;
  background: url("/src/img/drop-check.png");
  width: 10px;
  height: 8px;
}
@media (min-width: 600px) {
  .MuiSnackbar-anchorOriginBottomCenter {
    left: 120px !important;
    right: inherit !important;
    top: 60px !important;
    bottom: inherit !important;
    transform: none !important;
    width: calc(100vw - 150px);
  }
}
