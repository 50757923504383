@media screen and (min-width: 1600px) and (max-width: 3200px) {
  .MuiGrid-grid-xs-9.main-content-wrapper {
    flex-grow: 0;
    max-width: 82%;
    flex-basis: 82%;
  }
  .MuiGrid-grid-xs-3.header-main-wrapper {
    flex-grow: 0;
    max-width: 18%;
    flex-basis: 18%;
  }
  .MuiGrid-grid-xs-11.main-content-wrapper {
    flex-grow: 0;
    max-width: 93%;
    flex-basis: 93%;
  }
  .MuiGrid-grid-xs-1.header-main-wrapper {
    flex-grow: 0;
    max-width: 7%;
    flex-basis: 7%;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1599px) {
  .MuiGrid-grid-xs-9.main-content-wrapper,
  .MuiGrid-grid-xs-9.add-main-section-wrapper {
    flex-grow: 0;
    max-width: 78%;
    flex-basis: 78%;
  }
  .MuiGrid-grid-xs-3.header-main-wrapper {
    flex-grow: 0;
    max-width: 22%;
    flex-basis: 22%;
  }
  .MuiGrid-grid-xs-11.main-content-wrapper {
    flex-grow: 0;
    max-width: 93%;
    flex-basis: 93%;
  }
  .MuiGrid-grid-xs-1.header-main-wrapper {
    flex-grow: 0;
    max-width: 7%;
    flex-basis: 7%;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .MuiGrid-grid-xs-9.main-content-wrapper {
    flex-grow: 0;
    max-width: 72%;
    flex-basis: 72%;
  }
  .MuiGrid-grid-xs-3.header-main-wrapper {
    flex-grow: 0;
    max-width: 28%;
    flex-basis: 28%;
  }
  .MuiGrid-grid-xs-11.main-content-wrapper {
    flex-grow: 0;
    max-width: 91%;
    flex-basis: 91%;
  }
  .MuiGrid-grid-xs-1.header-main-wrapper {
    flex-grow: 0;
    max-width: 9%;
    flex-basis: 9%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .MuiGrid-grid-xs-9.main-content-wrapper {
    flex-grow: 0;
    max-width: 72%;
    flex-basis: 72%;
  }
  .MuiGrid-grid-xs-3.header-main-wrapper {
    flex-grow: 0;
    max-width: 28%;
    flex-basis: 28%;
  }
  .MuiGrid-grid-xs-11.main-content-wrapper {
    flex-grow: 0;
    max-width: 88%;
    flex-basis: 88%;
  }
  .MuiGrid-grid-xs-1.header-main-wrapper {
    flex-grow: 0;
    max-width: 12%;
    flex-basis: 12%;
  }
  .main-heading {
    font-size: 16px;
  }
  .makeStyles-tableWrap-20 .MuiTable-root .MuiTableHead-root th {
    font-size: 12px;
    padding: 5px;
  }
  .makeStyles-companyWrap-1 .company-section {
    padding: 10px 15px;
  }
  .main-heading span,
  .breadcrumb-heading span.breadcrumb {
    font-size: 11px;
  }
  .top-section .MuiGrid-grid-xs-6 {
    max-width: 100%;
    flex-basis: 100%;
  }
}
